/* @flow */
import React from 'react';
import ConnectedBot  from './components/connectedBot';
import './App.css';


function App() {
  
  return  (
    <ConnectedBot />
  );
}

export default App;
